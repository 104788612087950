<template>
  <div class="self-center">
    <div class="flex-row flex-wrap">
      <!-- View -->
      <b-button
        v-b-tooltip.hover.top="'Click here to view the Checkout Details'"
        class="btn-icon grid-btn"
        variant="outline-dark"
        @click="viewRecord"
      >
        <feather-icon
          icon="EyeIcon"
          svg-classes="hover:text-primary stroke-current"
        />
      </b-button>

      <!-- Review -->
      <b-button
        v-if="(this.params.data.status == 'Delivered')"
        v-b-tooltip.hover.top="'Review this Product'"
        class="btn-icon grid-btn"
        variant="outline-dark"
        @click="viewRecord"
      >
        <feather-icon
          class="text-warning"
          icon="ThumbsUpIcon"
          svg-classes="hover:text-primary stroke-current"
        />
      </b-button>
      <!-- Buy Again -->
      <b-button
        v-if="(this.params.data.status == 'Received')"
        v-b-tooltip.hover.top="'Update this Product'"
        class="btn-icon grid-btn"
        variant="outline-dark"
        @click="viewRecord"
      >
        <feather-icon
          class="text-warning"
          icon="EditIcon"
          svg-classes="hover:text-primary stroke-current"
        />
      </b-button>
      <!-- Cancel -->
      <b-button
        v-if="(this.params.data.status == 'Waiting Confirmation')"
        v-b-tooltip.hover.top="'Reject this Product'"
        class="btn-icon grid-btn"
        variant="outline-dark"
        @click="cancelRecord"
      >
        <feather-icon
          class="text-warning"
          icon="XOctagonIcon"
          svg-classes="hover:text-primary stroke-current"
        />
      </b-button>

    </div>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'CellRendererActions',

  components: {
    //
  },
  data() {
    return {
      isLoading: false,
    }
  },

  methods: {
    viewRecord() {
      this.$router.push({ name: 'marketplace-checkouts-view', params: { id: this.params.data._id } })
    },

    editRecord() {
      this.$router.push({ name: 'user-manager-edit', params: { email: this.params.data.email_address } })
    },

    invokeParentMethod(popup, edit_data) {
      this.params.context.componentParent.methodFromParent(popup, edit_data)
    },

    confirmDeactivateRecord() {
      if (this.$checkRights(this.$route.meta.section, 'update')) {
        this.$bvModal
          .msgBoxConfirm(
            `Are you sure you want to deactivate "${this.params.data.email}"`,
            {
              title: 'Confirm User Deletion',
              size: 'sm',
              okVariant: 'danger',
              okTitle: 'Deactivate',
              cancelTitle: 'Cancel',
              cancelVariant: 'outline-secondary',
              hideHeaderClose: false,
              centered: true,
            },
          )
          .then(value => {
            if (value) {
              this.deactivateRecord()
            }
          })
      } else {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Inadequate permission',
              icon: 'CheckCircleIcon',
              text: 'You dont have enough rights to delete data in this section',
              variant: 'danger',
              position: 'center',
            },
          },
          { timeout: this.$longestTimeout },
        )
      }
    },
    deactivateRecord() {
      const { email } = this.$store.state.auth.ActiveUser || {}
      const { user_group } = this.$store.state.auth.ActiveUser || {}

      if (email === this.params.data.email) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Operation Failed',
              icon: 'CheckCircleIcon',
              text: 'You cannot delete your own record.',
              variant: 'danger',
              position: 'center',
            },
          },
          { timeout: this.$longestTimeout },
        )
      } else {
        this.showDeactivateSuccess()

        // this.params.context.componentParent.get_company_users();
      }
    },
    showDeactivateSuccess() {
      const { token } = this.$store.state.auth.ActiveUser
      const { email } = this.params.data
      this.$http.defaults.headers.common.Authorization = `Token ${token}`
      this.isLoading = true

      this.$http
        .delete(`/api/users/${email}`, {})
        .then(response => {
          const output = response.data
          if (output.success) {
            this.params.context.componentParent.deleteSelected()
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Account operations',
                  icon: 'CheckCircleIcon',
                  text: 'Account deactivated successfully.',
                  variant: 'success',
                  position: 'center',
                },
              },
              { timeout: this.$longestTimeout },
            )
          } else {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Account operations',
                  icon: 'CheckCircleIcon',
                  text: 'Could not deactivate the user account please try again later',
                  variant: 'danger',
                  position: 'center',
                },
              },
              { timeout: this.$longestTimeout },
            )
          }
          this.isLoading = false
        })
        .catch(error => {
          this.$toast(
            {
              component: this.$toastContent,
              props: {
                title: 'Account operations',
                icon: 'CheckCircleIcon',
                text: 'Could not delete the user account please try again later',
                variant: 'danger',
                position: 'center',
              },
            },
            { timeout: this.$longestTimeout },
          )

          this.isLoading = false
        })
    },
  },
}
</script>
